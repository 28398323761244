@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */
.light .nav-link {
  color: #333; /* Light theme color */
}

.dark .nav-link {
  color: #fff; /* Dark theme color */
}

/* CSS */
/* Slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px; /* Customize the width of the slider handle */
  height: 24px; /* Customize the height of the slider handle */
  background-color: #4f46e5; /* Customize the color of the slider handle */
  border-radius: 50%; /* Ensure the slider handle is circular */
  border: solid #fff 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Customize the shadow of the slider handle */
  position: relative;
  z-index: 1; /* Ensure the slider handle is above other elements */
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 24px; /* Customize the width of the slider handle */
  height: 24px; /* Customize the height of the slider handle */
  background-color: #4f46e5; /* Customize the color of the slider handle */
  border-radius: 50%; /* Ensure the slider handle is circular */
  border: solid #fff 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Customize the shadow of the slider handle */
  position: relative;
  z-index: 1; /* Ensure the slider handle is above other elements */
}

/* Hide default styling for thumb */
input[type="range"]::-ms-thumb {
  display: none;
}

/* For IE */
input[type="range"]::-ms-fill-lower {
  background: transparent;
}

input[type="range"]::-ms-fill-upper {
  background: transparent;
}
